/* KnownOriginOwnersComponent.css */
/* Custom styles will be added here as needed */

/* Hide scrollbar on mobile */
.overflow-x-auto {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

/* Show scrollbar on desktop */
@media (min-width: 768px) {
  .desktop-scroll {
    scrollbar-width: thin;
    scrollbar-color: rgba(107, 114, 128, 0.5) transparent;
  }

  .desktop-scroll::-webkit-scrollbar {
    height: 8px;
    display: block;
  }

  .desktop-scroll::-webkit-scrollbar-track {
    background: rgba(31, 41, 55, 0.5);
    border-radius: 4px;
  }

  .desktop-scroll::-webkit-scrollbar-thumb {
    background: rgba(107, 114, 128, 0.5);
    border-radius: 4px;
  }

  .desktop-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(107, 114, 128, 0.7);
  }
}

/* Loading spinner */
.loader {
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 8px solid #f87171;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 