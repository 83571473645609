/* src/Components/XCOPYHodlersComponent.css */

/* Apply Montserrat font */
body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Spinner Styles */
.loader {
  border-top-color: #f87171; /* Tailwind's red-400 */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Table Styles */
.overflow-x-auto {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

table {
  width: 100%;
  border-spacing: 0;
  min-width: 800px; /* Ensure table doesn't get too narrow on mobile */
}

/* Table Header */
thead th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #111827;
}

/* Ensure proper spacing */
.space-y-8 > * + * {
  margin-top: 2rem;
}

/* Ensure proper table cell alignment */
td, th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add horizontal scroll indicators */
.overflow-x-auto {
  position: relative;
}

.overflow-x-auto::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.3));
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.overflow-x-auto:hover::after {
  opacity: 1;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .table-container {
    margin: 0 -1rem;
    padding: 0 1rem;
    width: calc(100% + 2rem);
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .pagination a {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }
} 