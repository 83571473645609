/* src/Components/Navbar.css */
/* No custom styles needed at the moment */

.navbar {
    position: relative;
    z-index: 1000;
}

/* Mobile Menu Animations */
.navbar .mobile-menu-enter {
    transform: translateX(-100%);
}

.navbar .mobile-menu-enter-active {
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
}

.navbar .mobile-menu-exit {
    transform: translateX(0);
}

.navbar .mobile-menu-exit-active {
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;
}

/* Prevent body scroll when mobile menu is open */
.menu-open {
    overflow: hidden;
}

/* Mobile Menu Shadow */
@media (max-width: 768px) {
    .navbar {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    
    /* Smooth sliding animation for mobile menu */
    .navbar [class*="transform"] {
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
}
