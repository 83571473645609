/* KnownOriginSalesComponent.css */
/* Custom styles will be added here as needed */

/* Loader animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border-top-color: #ef4444; /* Red-500 */
  animation: spin 1s linear infinite;
}

/* Custom scrollbar for desktop */
.desktop-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.desktop-scroll::-webkit-scrollbar-track {
  background: #1f2937; /* Gray-800 */
  border-radius: 4px;
}

.desktop-scroll::-webkit-scrollbar-thumb {
  background: #4b5563; /* Gray-600 */
  border-radius: 4px;
}

.desktop-scroll::-webkit-scrollbar-thumb:hover {
  background: #6b7280; /* Gray-500 */
}

/* Responsive table adjustments */
@media (max-width: 768px) {
  .desktop-scroll {
    -webkit-overflow-scrolling: touch;
  }
  table td:nth-child(2) img {
    width: auto;
    height: 80px;
    object-fit: contain;
  }
}

/* Keep the desktop scroll behavior */
.desktop-scroll {
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #1a202c;
}

.desktop-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.desktop-scroll::-webkit-scrollbar-track {
  background: #1a202c;
}

.desktop-scroll::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
} 