/* KnownOriginPricesComponent.css */
/* Custom styles will be added here as needed */

/* Hide scrollbar on mobile */
.overflow-x-auto {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

/* Show scrollbar on desktop */
@media (min-width: 768px) {
  .desktop-scroll {
    scrollbar-width: thin;
    scrollbar-color: #4a5568 #1a202c;
  }

  .desktop-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .desktop-scroll::-webkit-scrollbar-track {
    background: #1a202c;
  }

  .desktop-scroll::-webkit-scrollbar-thumb {
    background-color: #4a5568;
    border-radius: 4px;
  }

  .desktop-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(107, 114, 128, 0.7);
  }
}

/* Loading spinner */
.loader {
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 8px solid #f87171;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Table Styles */
table {
  width: 100%;
  border-spacing: 0;
  min-width: 1200px; /* Ensure table doesn't get too narrow on mobile */
}

/* Table Header */
thead th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #111827;
}

/* Ensure proper spacing */
.space-y-8 > * + * {
  margin-top: 2rem;
}

/* Ensure proper table cell alignment */
td, th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .table-container {
    margin: 0 -1rem;
    padding: 0 1rem;
    width: calc(100% + 2rem);
  }

  .pagination {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .pagination a {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
  }

  table td:nth-child(2) img {
    width: auto;
    height: 80px;
    object-fit: contain;
  }
} 