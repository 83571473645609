/* src/Components/HomeComponent.css */
/* Custom styles if needed */
.home-container {
    /* Currently empty; Tailwind handles styling */
  }
  
.nav-tile {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.nav-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

/* Known Origin links styling */
.ko-link {
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.ko-link:hover {
  transform: translateY(-2px);
}

.ko-link:active {
  transform: translateY(0);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .nav-tile {
    padding: 1.5rem;
  }
  
  .nav-tile h2 {
    font-size: 1.5rem;
  }
}
  